
import {useContext} from 'react';
import {Modal} from 'react-bootstrap';
import {ContentFsm} from './const';
import {UserContext, MenuPathContext} from './context'
import {Authenticator} from '@aws-amplify/ui-react';
import {awsconfig, region} from './config';

import { signIn, signInWithRedirect, associateWebAuthnCredential, ConfirmSignInOutput, listWebAuthnCredentials, deleteWebAuthnCredential } from "aws-amplify/auth"



import {useEffect} from 'react';

const defaultHeaders:any = {
    mode: 'no-cors',
    "Access-Control-Allow-Headers":     "*",
    "Access-Control-Allow-Methods":     "*",
    "Access-Control-Allow-Origin":      "*",
}


//signInWithRedirect({ provider: "Google" })

//import {awsconfig} from './config';
//Auth.federatedSignIn({provider: 'Cognito'});

//const domain   = awsconfig.Auth!.Cognito.loginWith!.oauth!.domain;
//const clientId = awsconfig.Auth!.Cognito.userPoolClientId;
//const redirect = awsconfig.Auth!.Cognito.loginWith!.oauth!.redirectSignIn;

//const url = `https://${domain}/passkeys/add?client_id=${clientId}&redirect_uri=${redirect}`;
//ttps://[MY-DOMAIN-NAME].auth.[REGION].amazoncognito.com/passkeys/add?client_id=[CLIENT-ID]&redirect_uri=[WEB-APP-ORIGIN].



export const WebAuthDeleteAll = () => {
    const { userStatus, setUserStatus } = useContext(UserContext);
    const { lastMenuPath, setLastMenuPath } = useContext(MenuPathContext);

    useEffect(() => {
        const si = async () => {
            const result = await listWebAuthnCredentials();

            const keys = result.credentials;

            for (const key of keys) {
                console.log('WebAuthDeleteAll delete', key.credentialId);
                await deleteWebAuthnCredential({
                    credentialId: key.credentialId!
                });
            }
            setLastMenuPath("");
            setUserStatus(ContentFsm.REVALIDATE);
        }   
        si();
    }, []);
    return (null);
}

export const WebAuthList = () => {
    const { userStatus, setUserStatus } = useContext(UserContext);
    const { lastMenuPath, setLastMenuPath } = useContext(MenuPathContext);

    useEffect(() => {
        const si = async () => {
            const result = await listWebAuthnCredentials();
            //const result = await associateWebAuthnCredential();
            //const response = await fetch(url, {headers: defaultHeaders});
            //const data = await response.json();

            console.log('listWebAuthnCredentials', result);
            //for (const credential of result.credentials) {
            //    console.log(`Credential ID: ${credential.credentialId}`);
            //    console.log(`Friendly Name: ${credential.friendlyCredentialName}`);
            //    console.log(`Relying Party ID: ${credential.relyingPartyId}`);
            //    console.log(`Created At: ${credential.createdAt}`);
           // }
           setLastMenuPath("");
           setUserStatus(ContentFsm.REVALIDATE);
        }   
        si();
    }, []);
    return (null);
}


export const WebAuthAdd = () => {
    const { userStatus, setUserStatus } = useContext(UserContext);
    const { lastMenuPath, setLastMenuPath } = useContext(MenuPathContext);

    useEffect(() => {
        const si = async () => {
            const result = await associateWebAuthnCredential();
            //await fetch(url, {headers: defaultHeaders});
            //const response = await fetch(url, {headers: defaultHeaders});
            //console.log('associateWebAuthnCredential', result);
            setLastMenuPath("");
            setUserStatus(ContentFsm.REVALIDATE);
        }   
        si();
    }, []);
    return (null);
}

export const SignIn = () => {
    const { userStatus, setUserStatus } = useContext(UserContext);

    const no = () => { 
        setUserStatus(ContentFsm.REVALIDATE);
    }

    /**************IF UNCOMMENTED THE APP WILL USE HOSTED UI LOGIN*/
    useEffect(() => {
        const si = async () => {
            await signInWithRedirect();
            //await signIn();
        }
        si();
    }, []);
    return (null);
    /*****************/

    return (
         <>

            <Modal   
                show={true} 
                onHide={no}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                size="sm"
            >
                <Authenticator 
                    loginMechanisms={['email']}
                    hideSignUp = {true}
                >
                </Authenticator>
        
        

            </Modal>

         </>
    );
}
