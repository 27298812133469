import {useEffect} from 'react';
import {RoutingValueProps} from './types'
import {host} from './config'

export const GotoUrl = (props: RoutingValueProps) => {

  
  useEffect(() => {
      const call = async () => {

        const propsCopy:RoutingValueProps = {...props};
        // propsCopy.overide should be a full path (if speficfied), propsCopy.path is relative to current host
        const propsUrl:string = (propsCopy.hasOwnProperty('overidePath')) ? propsCopy.overidePath! : `${host}${propsCopy.path}`;

        //console.debug('GotoUrl', propsCopy, propsUrl);

        window.location.replace(`${propsUrl}`);
      }
      call();
  }, []);
  return (null);
}